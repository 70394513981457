import Insight from "../insightClass";
import React from "react";
import { insight25Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";

export const insight025 = new Insight(
  "9 December 2024",
  {
    pageTitle:
      "Key Differences Between Chinese and European Jackets In Offshore Wind",
    metaDescription:
      "WeBridge Insights, Key Differences Between Chinese and European Jackets In Offshore Wind",
    keyWords:
      "European Offshore Wind Market development, Key Differences Between Chinese and European Jackets In Offshore Wind",
  },
  {
    src: insight25Media[0],
    alt: "offshore wind manufacturing",
  },
  "Key Differences Between Chinese and European Jackets In Offshore Wind",
  "",
  `The design and fabrication of offshore wind jackets are pivotal to the success of the wind energy sector, as these structures must endure harsh marine environments while reliably supporting turbines to maximize energy production. At WeBridge, we recognize that the differences between Chinese and European offshore wind jackets are shaped by distinct engineering philosophies, regulatory frameworks, technological advancements, and economic priorities.`,
  (
    <div>
      <strong>WeBridge Insights</strong>
      <h1>
        Key Differences Between Chinese and European Jackets In Offshore Wind
      </h1>
      <h2></h2>
      <p style={{ opacity: "0.4", marginTop: "-70px" }}>9 December 2024</p>

      <p>
        The design and fabrication of offshore wind jackets are pivotal to the
        success of the wind energy sector, as these structures must endure harsh
        marine environments while reliably supporting turbines to maximize
        energy production. At WeBridge, we recognize that the differences
        between Chinese and European offshore wind jackets are shaped by
        distinct engineering philosophies, regulatory frameworks, technological
        advancements, and economic priorities.
      </p>
      <p>
        This analysis provides an in-depth exploration of these differences,
        focusing on critical areas such as:
      </p>
      <ul>
        <li>Design standards and methodologies</li>
        <li>Material selection</li>
        <li>Structural dimensions</li>
        <li>Fabrication standards and welding techniques, and </li>
        <li>Project management and quality assurance. </li>
      </ul>
      <br />
      <p>
        By examining these factors from a technical and professional
        perspective, we aim to offer valuable insights into the strengths and
        challenges of each approach, contributing to more informed
        decision-making in the offshore wind industry.
      </p>
      <img
        src={insight25Media[0]}
        alt="offshore wind manufacturing"
        style={{ marginBottom: "48px" }}
      />

      <h3>Design Standards and Methodologies</h3>
      <p>
        <b>European Jackets:</b> European offshore wind jackets are engineered
        to comply with internationally recognized standards, including ISO, IEC,
        and DNV. These standards dictate rigorous requirements for structural
        integrity, fatigue resistance, and environmental adaptability. Designs
        are tailored for harsher conditions, such as deeper waters, stronger
        wind forces, and greater wave loads. Advanced simulation tools, such as
        integrated load analysis (ILA), finite element analysis (FEA) and
        computational fluid dynamics (CFD), are integral to ensuring the
        long-term reliability of these structures.
      </p>
      <p>
        <b>Chinese Jackets:</b> Chinese designs adhere primarily to domestic
        standards such as GB, DL, and NB/T. While these designs meet local
        requirements, they focus on cost-effectiveness and scalability. The
        design and fabrication methods are tailored for less severe
        environmental conditions, reducing production costs. This approach
        reflects an emphasis on practicality and economic feasibility, often at
        the expense of advanced engineering practices.
      </p>
      <img
        src={insight25Media[1]}
        alt="offshore wind manufacturing"
        style={{ marginBottom: "48px", width: "70%", marginLeft: "15%" }}
      />
      <h3>Material Selection</h3>
      <p>
        <b>European Approach:</b> Materials are selected based on third-party
        certification from organisations like DNV and ISO ensuring compliance
        with stringent standards for fatigue and corrosion resistance.
        High-grade steels, such as S355, S420, and S460, are commonly used, with
        greater thicknesses to withstand extreme conditions.
      </p>
      <p>
        <b>Chinese Approach:</b> Locally sourced materials, such as Q345-grade
        steel, are standard. These materials are often uncertified by
        international bodies, and their use is driven by cost considerations.
        While adequate for local applications, additional testing and quality
        assurance may be necessary for international projects.
      </p>

      <h3>Structural Dimensions</h3>
      <p>
        <b>European Jackets:</b> More than 20% of the wall thicknesses can
        exceed 100mm, reaching up to 150mm, requiring advanced rolling machinery
        for precise fabrication. Low diameter-to-thickness (D/t) ratios,
        typically below 20, further enhance structural stability but demand
        superior engineering and material handling capabilities.
      </p>
      <p>
        <b>Chinese Jackets:</b> Wall thicknesses are generally capped at 100mm,
        and D/t ratios exceed 20, simplifying the rolling process and reducing
        fabrication challenges. However, this approach may limit the structural
        resilience of the jackets in extreme conditions.
      </p>

      <h3>Fabrication Standards and Welding Techniques</h3>
      <p>
        <b>European Standards:</b> Welding practices are held to the highest
        standards, with welders undergoing rigorous qualification examinations,
        including compliance with EN ISO 9606-1. Dual-sided welding is mandatory
        for critical nodes (e.g., TKY connections), ensuring joint strength and
        fatigue resistance. Dimensional control standards such as DNV-OS-C401
        are strictly applied, employing laser trackers and 3D scanning for
        precision.
      </p>
      <p>
        <b>Chinese Practices:</b> Welding qualifications are less stringent,
        with single-sided welding commonly employed for jacket and brace TKY
        nodes. While this simplifies production, it can compromise joint
        integrity. Dimensional control practices are less rigorous, relying on
        older standards that may not meet international expectations.
      </p>
      <img
        src={insight25Media[2]}
        alt="offshore wind manufacturing"
        style={{ marginBottom: "48px", width: "70%", marginLeft: "15%" }}
      />
      <h3>Project Management and Quality Assurance</h3>
      <p>
        <b>European Model:</b> European offshore wind projects emphasize
        dedicated project management teams with specialized roles across
        fabrication, HSE (health, safety, and environment), quality assurance,
        and engineering disciplines. Enhanced coordination ensures strict
        compliance with timelines and performance standards, while third-party
        supervision by classification societies like DNV provides an additional
        layer of quality control.
      </p>
      <p>
        <b>Chinese Model:</b> Chinese project management approaches are less
        resource-intensive, often involving smaller teams with fewer specialized
        personnel. A Project manager may oversee multiple projects
        simultaneously, leading to limited oversight. Quality assurance relies
        heavily on internal checks, with minimal third-party involvement,
        particularly for domestic projects.
      </p>
      <img
        src={insight25Media[3]}
        alt="offshore wind manufacturing"
        style={{ marginBottom: "48px" }}
      />
      <h3>Conclusions</h3>
      <p>
        The differences between Chinese and European offshore wind jackets are
        deeply rooted in their respective economic and industrial contexts.
        European jackets are ideal for high-performance applications in
        challenging offshore environments, where reliability and longevity are
        paramount. In contrast, Chinese jackets offer an economical solution for
        less demanding conditions, aligning with markets that prioritize
        scalability and cost efficiency.
      </p>
      <h3>The Path Forward: Convergence of Practices</h3>
      <p>
        As the offshore wind industry continues to globalize, the fabrication of
        European-designed jackets in China is becoming increasingly common. This
        trend fosters knowledge exchange and technological collaboration, paving
        the way for a convergence of methodologies. By combining European
        expertise in advanced engineering and stringent quality standards with
        China’s cost-effective manufacturing capabilities, the industry has the
        potential to create hybrid solutions that maximize performance while
        remaining economically viable.
      </p>
      <p>
        Innovations in material science, fabrication techniques, and design
        optimisation will play a pivotal role in this evolution. Hybrid
        approaches could blend high-grade materials and precise engineering from
        Europe with the scalable, efficient production processes honed in China,
        addressing both performance demands and market competitiveness.
      </p>
      <p>
        For WeBridge, this convergence represents a strategic opportunity to
        lead the industry in developing innovative, cost-effective, and robust
        solutions. By leveraging the strengths of both European and Chinese
        methodologies, WeBridge can contribute to advancing global renewable
        energy goals while meeting the challenges of an increasingly competitive
        market.
      </p>

      <h3>Summary Table</h3>
      <p>
        This table below encapsulates the primary distinctions between European
        and Chinese offshore wind jackets, reflecting their respective
        priorities and technological approaches.
      </p>
      <h4 style={{ textAlign: "center", marginTop: "48px" }}>
        Summary Table: European Jackets vs Chinese Jackets
      </h4>
      <table className="insight-table">
        <tr>
          <th>Aspect</th>
          <th>European Jackets</th>
          <th>Chinese Jackets</th>
        </tr>
        <tr>
          <td>Design Standards</td>
          <td>
            Adhere to international standards (ISO, IEC, DNV) for extreme
            environments and long-term resilience.ISO, IEC, DNV
          </td>
          <td>
            Follow domestic standards (GB, DL, NB/T) optimized for local
            conditions and cost-efficiency.
          </td>
        </tr>
        <tr>
          <td>Design Philosophy</td>
          <td>
            Advanced simulation and precision engineering for harsh marine
            conditions.
          </td>
          <td>
            Simplified designs focusing on scalability and economic feasibility.
          </td>
        </tr>
        <tr>
          <td>Material Quality</td>
          <td>
            Certified materials (S355/S420/S460) with third-party oversight
            ensuring fatigue and corrosion resistance.
          </td>
          <td>
            Locally sourced lower-grade materials (Q345), often uncertified by
            international standards.
          </td>
        </tr>
        <tr>
          <td>Wall Thickness</td>
          <td>
            Approx. 20% of the wall thicknesses can exceed 100mm, reaching up to
            150mm. This demands the use of advanced machinery and highly precise
            fabrication techniques to ensure structural integrity and compliance
            with stringent quality standards.
          </td>
          <td>Typically limited to 100mm, enabling simpler fabrication.</td>
        </tr>
        <tr>
          <td>D/t Ratio</td>
          <td>
            Below 20, providing enhanced stability but demanding advanced
            fabrication.
          </td>
          <td>
            Exceeds 20, simplifying rolling processes and reducing production
            complexity.
          </td>
        </tr>
        <tr>
          <td>Welding Standards</td>
          <td>
            Dual-sided welding for critical nodes, strict adherence to
            DNV-OS-C401 and EN ISO 9606-1.
          </td>
          <td>
            Single-sided welding, minimal certification requirements, less
            rigorous dimensional control.
          </td>
        </tr>
        <tr>
          <td>Dimensional Control</td>
          <td>
            Laser trackers, 3D scanning, and strict international standards
            (DNV-OS-C401, EEMUA 158).
          </td>
          <td>
            Less precise dimensional control practices, following older
            standards.
          </td>
        </tr>
        <tr>
          <td>Project Management</td>
          <td>
            Full-time teams with specialized roles (HSE, QA/QC, procurement) and
            third-party supervision (e.g., DNV).
          </td>
          <td>
            Smaller, multitasking teams with limited specialization and
            oversight.
          </td>
        </tr>
        <tr>
          <td>Environmental Resilience</td>
          <td>
            Engineered for harsh environments: deeper waters, stronger winds,
            severe wave loads.
          </td>
          <td>
            Designed for less demanding environments with lower environmental
            loads.
          </td>
        </tr>
        <tr>
          <td>Fabrication Technology</td>
          <td>Advanced machinery and techniques for precision fabrication.</td>
          <td>Standard machinery focusing on efficiency and simplicity.</td>
        </tr>
        <tr>
          <td>Inspection Standards</td>
          <td>
            Comprehensive third-party inspection, including strain aging and
            impact testing (-40°C to -50°C).
          </td>
          <td>
            Minimal third-party inspection, focusing on basic compliance with
            domestic standards.
          </td>
        </tr>
        <tr>
          <td>Design Life</td>
          <td>
            27 to 35 years with robust structural integrity and fatigue
            resistance.
          </td>
          <td>Around 27 years, adequate for localized conditions.</td>
        </tr>
        <tr>
          <td>Cost</td>
          <td>
            Higher due to advanced materials, technology, and rigorous
            standards.
          </td>
          <td>
            Half price and even lower, driven by local materials, simplified
            processes, and reduced certification requirements.
          </td>
        </tr>
      </table>
      <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
      <ContactSubscribe />
    </div>
  )
);
