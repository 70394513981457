import React, { useState } from "react";
import "./Footer.scss";
import { NavLink } from "react-router-dom";
import { companyInformation } from "../../data/content/content";
import {
  backgroundArtArray,
  iconArray,
  other,
  weBridgeLogo,
} from "../../assets/assetReference";

export default function Footer() {
  const [showWechatQR, setShowWechatQR] = useState(false);
  return (
    <footer className={"footer-container"}>
      <div className="social flex">
        <img
          className="logo"
          src={weBridgeLogo.white}
          alt="webridge company logo in white"
        />
        <div className="flex">
          <a
            className="container-icon"
            href="https://www.linkedin.com/company/webridge-global/"
            target="_blank"
          >
            <img className="i" src={iconArray.linkedin} />
          </a>
          <div
            className="container-icon"
            onMouseEnter={() => {
              setShowWechatQR(true);
            }}
            onMouseLeave={() => {
              setShowWechatQR(false);
            }}
            onClick={() => {
              setShowWechatQR(true);
            }}
          >
            <img className="i" src={iconArray.wechat} />
            {showWechatQR && (
              <div id="wechat-qr">
                <img className="o" src={other.wechatQR} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="content">
        <address>
          <p>Contact Us</p>
          <div>
            {companyInformation.address.split(";").map((i) => {
              return <p key={i}>{i}</p>;
            })}
          </div>
          <p>{companyInformation.phone}</p>
          <p>
            <a
              className="a-no-decor"
              href={`mailto:${companyInformation.email}`}
            >
              {companyInformation.email}
            </a>
          </p>
        </address>
        <ul
          className="list-no-style"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <li>
            <NavLink className="a-no-decor" to="/about-us">
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink className="a-no-decor" to="/services/for-developers">
              For Developers
            </NavLink>
          </li>
          <li>
            <NavLink className="a-no-decor" to="/services/for-suppliers">
              For Suppliers
            </NavLink>
          </li>
          <li>
            <NavLink className="a-no-decor" to="/contact-us">
              Contact Us
            </NavLink>
          </li>
        </ul>

        <ul className="list-no-style">
          <li>
            <NavLink className="a-no-decor" to="/policies/terms-and-conditions">
              Terms & Conditions
            </NavLink>
          </li>
          <li>
            <NavLink className="a-no-decor" to="/policies/privacy-policy">
              Privacy Policy
            </NavLink>
          </li>
          <li>
            <NavLink className="a-no-decor" to="/policies/cookies-policy">
              Cookies Policy
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="copyright">
        <p>
          &copy;{new Date().getFullYear()} Copyright WeBridge. All rights
          reserved.
        </p>
        <p>
          Wind Energy Bridge Limited, registered in England and Wales. Company
          No.: 14963030.
        </p>
      </div>
      <img
        className="background"
        src={backgroundArtArray.windmillWaterArt}
        alt="2d offshore windmill art for background"
      />
    </footer>
  );
}
