import Insight from "../insightClass";
import React from "react";
import { insight29Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";

export const insight029 = new Insight(
  "24 January 2025",
  {
    pageTitle: "Global Offshore Wind Weekly",
    metaDescription: "WeBridge Insights, Global Offshore Wind Weekly",
    keyWords:
      "European Offshore Wind Market development, Global Offshore Wind Weekly",
  },
  {
    src: insight29Media[0],
    alt: "offshore wind turbine",
  },
  "Global Offshore Wind Weekly (2025-01-24)",
  "",
  `Orsted Hornsea Project Four (UK) Limited announced that geotechnical survey operations will commence in February 2025 for the Hornsea Project Four Offshore Wind Farm, located off the East Yorkshire coast.`,
  (
    <div>
      <strong>WeBridge Insights</strong>
      <h1>Global Offshore Wind Weekly (2025-01-24)</h1>
      <h2></h2>
      <p style={{ opacity: "0.4", marginTop: "-70px" }}>24 January 2025</p>
      <br />
      <p>
        Welcome to this week's <i>WeBridge Insights</i>, where we provide you
        with the latest updates and key developments from the global offshore
        wind market.
      </p>
      <br />
      <br />
      <br />
      <p>
        At <b>WeBridge</b>, we deliver forward-thinking analysis and expert
        commentary to keep you informed in this fast-evolving industry. Here's
        our roundup of the top stories this week, complete with professional
        insights to help you stay ahead:
      </p>

      <h3>
        1. Geotechnical Surveys to Begin for Hornsea Four Offshore Wind Farm
      </h3>
      <p>
        Orsted Hornsea Project Four (UK) Limited announced that geotechnical
        survey operations will commence in February 2025 for the Hornsea Project
        Four Offshore Wind Farm, located off the East Yorkshire coast.{" "}
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        Initiating geotechnical surveys is a critical step in offshore wind farm
        development. These surveys will provide essential data on seabed
        conditions, informing foundation design and installation strategies,
        thereby mitigating potential risks during construction.
      </p>

      <h3>
        2. Aker Solutions & Siemens Energy Awarded Full Notice to Proceed on
        Norfolk Vanguard West and East
      </h3>
      <p>
        Aker Solutions, in consortium with Siemens Energy, received the full
        notice to proceed from RWE for the Norfolk Vanguard West and East
        offshore wind farms.{" "}
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        This milestone underscores the project's progression into the execution
        phase. The collaboration between Aker Solutions and Siemens Energy is
        poised to leverage their combined expertise in delivering efficient and
        reliable offshore wind infrastructure.
      </p>

      <h3>
        3. RWE's Climate Targets Align with 1.5-Degree Pathway, Confirms SBTi
      </h3>
      <p>
        The Science Based Targets initiative (SBTi) validated RWE's climate
        targets for 2030 and its ambition to achieve net zero by 2040,
        confirming alignment with the Paris Agreement's goal of limiting global
        warming to 1.5 degrees.{" "}
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        RWE's alignment with the 1.5-degree pathway reflects the company's
        commitment to sustainable energy practices. This validation may enhance
        investor confidence and set a benchmark for other energy companies
        aiming for net-zero emissions.
      </p>

      <h3>
        4. Applications Open for Offshore Wind Farm Licence in Saare 7 Area
      </h3>
      <p>
        The Consumer Protection and Technical Regulatory Authority (CPTRA)
        announced an opportunity to submit applications for a superficies
        licence to establish an offshore wind farm in the Saare 7 area,
        approximately 11 km west of Saaremaa.{" "}
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        Opening applications for the Saare 7 area signifies Estonia's proactive
        approach to expanding its offshore wind capacity. This initiative could
        attract significant investments and contribute to the Baltic region's
        renewable energy targets.
      </p>

      <h3>
        5. U.S. DOE Unveils 'West Coast Offshore Wind Transmission Study and
        Action Plan'
      </h3>
      <p>
        The U.S. Department of Energy released the 'West Coast Offshore Wind
        Transmission Study and Action Plan', detailing strategies for advancing
        offshore wind transmission development along the U.S. Pacific coast.{" "}
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        The DOE's action plan addresses critical infrastructure needs for
        integrating offshore wind into the grid. Focusing on transmission
        development is essential for harnessing the West Coast's wind potential
        and ensuring energy reliability.
      </p>

      <h3>
        6. Global Offshore Wind Industry Faces Challenges and Opportunities as
        Growth Continues
      </h3>
      <p>
        The offshore wind industry is on the cusp of substantial growth,
        presenting both opportunities and challenges. A comprehensive report by
        Allianz Commercial highlights key areas such as technological
        innovations, risk trends, and growth prospects.{" "}
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        As the offshore wind sector expands, stakeholders must navigate complex
        challenges, including technological risks and economic pressures.
        Embracing innovation while implementing robust risk management
        strategies will be crucial for sustainable growth.
      </p>

      <h3>7. Record Year in Global Offshore Wind</h3>
      <p>
        TGS | 4C Offshore declared 2023 a record year for offshore wind
        investment, with projects totaling 12.3 GW reaching Final Investment
        Decision (FID), marking a strong recovery from the previous year.{" "}
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        The significant increase in FIDs indicates a robust rebound in investor
        confidence within the offshore wind sector. This momentum is expected to
        continue, with projections suggesting another record year ahead, further
        solidifying offshore wind's role in the global energy transition.
      </p>

      <br />
      <br />
      <br />
      <p>
        As the global offshore wind sector continues to advance, innovation,
        investments, and supportive policies are driving transformative growth.
        At{" "}
        <i>
          <b>WeBridge</b>
        </i>
        , we provide the expertise and insights our partners need to confidently
        navigate this evolving landscape.{" "}
      </p>
      <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
      <ContactSubscribe />
    </div>
  )
);
